<template>
  <div class="about">
    <h1>ピッキングリスト 出力</h1>
    <v-card class="pa-5" flat outlined>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="excel()">出力</Btn>
        <!--<v-btn class="" dark text outlined @click="excel()">Excel出力</v-btn>-->
      </v-card-actions>
    </v-card>
    <v-form v-model="valid" ref="form">
      <v-card class="pa-5" flat outlined>
        <v-row>
          <v-col colspan="12">
            <DatePicker
              v-model="dateFrom"
              label="対象年月日 FROM"
              prepend-inner-icon="mdi-calendar"
              type="day"
              outlined
            />
          </v-col>
          <div style="top: 0; bottom: 0; margin: auto;"><label>～</label></div>
          <v-col colspan="12">
            <DatePicker
              v-model="dateTo"
              label="対象年月日 TO"
              prepend-inner-icon="mdi-calendar"
              type="day"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col colspan="12">
            <v-autocomplete
              v-model="tokuiList"
              :items="tokui"
              label="得意先"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="name"
              clearable
              chips
              deletable-chips
              multiple
              return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-radio-group
          v-model="selectRadio"
          row
          label="出力切替:"
          prepend-icon="mdi-toggle-switch"
          mandatory
        >
          <v-radio label="実数" :value="radioValue.jissuu" />
          <v-radio label="発注数" :value="radioValue.hattyusuu" />
        </v-radio-group>
      </v-card>
    </v-form>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import moment from "moment";

const NEXT_DATE = moment()
  .add(1, "d")
  .format("YYYY-MM-DD");

export default {
  name: "ExcelPicking",
  mixins: [Common, Forms, ShowDialogs, Api],
  components: {},
  data: () => ({
    selectRadio: "",
    dateFrom: NEXT_DATE,
    dateTo: NEXT_DATE,
    radioValue: {
      jissuu: "jissuu",
      hattyusuu: "hattyusuu"
    },
    excelData: [],
    tokui: [],
    tokuiList: []
  }),
  computed: {
    likesAllTokui() {
      return this.tokuiList.length === this.tokui.length;
    },
    likesSomeTokui() {
      return this.tokuiList.length > 0 && !this.likesAllTokui;
    },
    icon() {
      if (this.likesAllTokui) return "mdi-close-box";
      if (this.likesSomeTokui) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllTokui) {
          this.tokuiList = [];
        } else {
          this.tokuiList = this.tokui.slice();
        }
      });
    },
    async excel() {
      if (!this.$refs.form.validate()) return;
      console.log("excel:download", "start");

      const dateFrom = moment(this.dateFrom);
      const dateTo = moment(this.dateTo);
      const tokuiList = this.tokuiList.map(ele => ele.tokuiId);

      if (dateFrom > dateTo) {
        alert("FromにはToより前の日付を指定してください。");
        return;
      }
      const termDay = dateTo.diff(dateFrom, "days");
      if (termDay > 40) {
        alert("From～Toの差日は40日以下にしてください。");
        return;
      }

      const confirm = await this.$confirm(
        "ピッキングリストをダウンロードしますか？",
        "EXCEL出力"
      );
      if (!confirm) return;
      this.$loading();

      try {
        const param = {
          dateFrom: dateFrom.format("YYYY-MM-DD"),
          dateTo: dateTo.format("YYYY-MM-DD"),
          tokuiList: tokuiList,
          type: "picking",
          output: this.selectRadio
        };

        const encode = encodeURI(JSON.stringify(param));

        const result = await this.$downloadexcel(
          "exceljyutyu/output",
          "query=" + encode
        );

        if (result.size == 0) {
          alert("該当するデータがありません。");
          return;
        }

        const url = URL.createObjectURL(result);
        console.log(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "ピッキングリスト.zip";
        link.click();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
        console.log("excel:download", "end");
      }
    },
    async getCodeMasterItems() {
      console.log("getExist");
      const pulldown = await this.$get(this.Paths.tokuiGroupPullDown);
      console.log(pulldown);
      this.tokui = pulldown.map(ele => {
        return {
          tokuiId: ele.id,
          name: ele.nm1 + ele.nm2 + "：" + ele.code,
          ckbn: ele.ckbnName
        };
      });
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created", this.date);
    this.$loading();
    await this.getCodeMasterItems();
    try {
      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }
  }
};
</script>
