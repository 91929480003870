<template>
  <div>
    <h1>受発注データ 出力</h1>
    <v-card outlined class="mb-5">
      <!-- <v-expansion-panels flat v-model="panel">
        <v-expansion-panel> -->
      <!-- Header -->
      <!-- <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content> -->
      <v-row>
        <v-col colspan="12">
          <DatePicker
            class="mt-10"
            v-model="dateFrom"
            prepend-inner-icon="mdi-calendar"
            label="対象年月日 FROM"
            type="day"
            outlined
          />
        </v-col>
        <div style="top: 0; bottom: 0; margin: auto;"><label>～</label></div>
        <v-col colspan="12">
          <DatePicker
            v-model="dateTo"
            label="対象年月日 TO"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
          />
        </v-col>
      </v-row>
      <!-- </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getselect()">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-actions class="ma-2">
        <span style="color:red"
          >連携用ファイルをダウンロードする場合は、対象の□に☑を入れてください。</span
        >
        <v-spacer />
        <Btn
          icon="mdi-download"
          color="success"
          text
          outlined
          @click="download"
          :disabled="!selected.length"
        >
          連携ファイルダウンロード
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        multi-sort
        :items-per-page="-1"
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="item.disabled"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { BUSYO_CODE as BusyoCode } from "@/defines";
import moment from "moment";

const DATE = moment().format("YYYY-MM-DD");

export default {
  name: "JyutyuOutput",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      dateFrom: DATE,
      dateTo: DATE,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      disabledCount: 0,
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    selectAllToggle(props) {
      console.log("selectAllToggle", props);
      if (!props.value) {
        this.selected = [];
        return;
      }
      if (this.selected.length != this.items.length - this.disabledCount) {
        this.selected = [];
        props.items.forEach(item => {
          //if (!item.disabled) {
          this.selected.push(item);
          //}
        });
      } else this.selected = [];
    },
    async getselect() {
      const dateFrom = moment(this.dateFrom);
      const dateTo = moment(this.dateTo);
      if (dateFrom > dateTo) {
        alert("FromにはToより前の日付を指定してください。");
        return;
      }
      const termDay = dateTo.diff(dateFrom, "days");
      if (termDay > 40) {
        alert("From～Toの差日は40日以下にしてください。");
        return;
      }
      const param = {
        datefrom: dateFrom.format("YYYY-MM-DD"),
        dateto: dateTo.format("YYYY-MM-DD"),
        //ckbn: ckbn
        ckbn: 1
      };

      await this.getSelectWithParam(param);
    },
    async getSelectWithParam(param) {
      console.log("getselect");
      this.$loading();
      try {
        this.selected = [];
        this.disabledCount = 0;
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const data = await this.$get("csvlist", "query=" + encode);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
      this.items.map(item => {
        if (item.disabled) this.disabledCount += 1;
      });
    },
    async download() {
      console.log("download", this.selected);
      if (this.selected.length == 0) {
        this.$warning("伝票が選択されていません");
        return;
      }

      const confirm = await this.$confirm(
        "受発注データをダウンロードしますか？",
        "CSV出力"
      );
      if (!confirm) return;

      this.$loading();

      try {
        const denpyoNos = [];
        this.selected.forEach(s => {
          denpyoNos.push(s.denpyoNo);
        });

        const param = {
          denpyoNos: denpyoNos
        };

        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const check = await this.$get(
          this.Paths.exportcheckjyutyu,
          "query=" + encode
        );

        if (check.errbody && check.errbody.length > 0) {
          this.isShowErrorList = true;
          this.headers = check.errheaders;
          this.items = check.errbody;
          alert("不正な受発注データが存在する為、ファイルが作成できません。");
          return;
        }

        const result = await this.$downloadcsv("csvexport", "query=" + encode);
        if (result.size == 0) {
          alert("該当するデータがありません。");
          return;
        }

        const url = URL.createObjectURL(result);
        console.log(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "受発注データ.csv";
        link.click();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
      await this.getselect();
    }
  },
  async created() {
    console.log("==created");
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
